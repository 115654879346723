






















































import { Component, Vue } from 'vue-property-decorator';

import { ISiteParserTaskCreate } from '@/interfaces/site-parser';
import {
dispatchCreateSiteParserTask,
dispatchGetSiteParserDomains,
dispatchGetSiteParserTasks,
} from '@/store/site-parser/actions';
import { readSiteParserDomains } from '@/store/site-parser/getters';
import { domainRules, nameRules } from './validators';

@Component
export default class SiteParserCreateTask extends Vue {
  public submitting = false;
  public domains: string[]|null = null;
  public valid = false;
  public name = '';
  public description = '';
  public mode = false;
  public nameRules = nameRules;
  public domainRules = domainRules;

  public get domainList() {
    const domains = readSiteParserDomains(this.$store);
    domains.sort();
    return domains;
  }

  public async mounted() {
    await dispatchGetSiteParserTasks(this.$store);
    await dispatchGetSiteParserDomains(this.$store);
    this.reset();
  }

  public reset() {
    this.name = this.description = '';
    this.domains = null;
    this.mode = false;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.submitting = true;
      const createdTask: ISiteParserTaskCreate = {
        name: this.name,
        description: this.description || undefined,
        domains: this.domains || [],
        mode: this.mode ? 'demo' : 'prod',
      };
      await dispatchCreateSiteParserTask(this.$store, createdTask);
      this.$router.push('/main/site-parser');
    }
  }
}
